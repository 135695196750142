export default (el, props) => {

    const init = () => {
        const chart = new Chart(el.getContext('2d'), {
            type: 'pie',
            tooltipTemplate: '<%= value %>%',
            data: {
                labels: props.labels,
                datasets: [{
                    backgroundColor: [
                        '#0A1F54',
                        '#F1534C',
                        '#00a0c0',
                        '#90c000'

                    ],
                    borderColor: [
                        '#0A1F54',
                        '#F1534C',
                        '#00a0c0',
                        '#90c000'
                    ],
                    data: props.values
                }]
            },
            options: {
                legend: {
                    display: true,
                    position: 'right',
                    labels: {
                        boxWidth: 12,
                        fontColor: '#222222'
                    }
                },
                tooltips: {
                    xPadding: 12,
                    yPadding: 12,
                    titleFontSize: 16,
                    bodyFontSize: 16,
                    titleFontFamily: 'Gotham',
                    bodyFontFamily: 'Gotham'
                }
            }
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
