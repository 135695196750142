import gsap from 'gsap';
import { Quart, Sine, Linear, Quint, Circ } from 'gsap/gsap-core';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default el => {
    const dom = $(el);
    const html = $('html');
    const body = $('body');
    const menu = dom.find('[data-menu]').get(0);

    const menuLinks = dom.find('a[data-menu-item]');

    const hamburger = dom.find('[data-hamburger]');
    const hamburgerTop = hamburger.find('i:nth-of-type(1)').get(0);
    const hamburgerMiddle = hamburger.find('i:nth-of-type(2)').get(0);
    const hamburgerBottom = hamburger.find('i:nth-of-type(3)').get(0);

    let menuOpen = false;
    let activeElement = null;

    const hamburgerToX = () => {
        const offset = hamburgerTop.offsetHeight + parseInt($(hamburgerTop).css('marginBottom'), 10);
        gsap.to(hamburgerTop, { duration: 0.15, y: offset, ease: Sine.easeIn });
        gsap.to(hamburgerBottom, {
            duration: 0.15,
            y: offset * -1,
            ease: Sine.easeIn,
            onComplete: () => {
                gsap.set(hamburgerMiddle, { opacity: 0 });
                gsap.to(hamburgerTop, { duration: 0.2, rotationZ: '45deg', transformOrigin: '50% 50%', ease: Quart.easeOut });
                gsap.to(hamburgerBottom, { duration: 0.2, rotationZ: '-45deg', transformOrigin: '50% 50%', ease: Quart.easeOut });
            }
        });
    };

    const xToHamburger = () => {
        gsap.to(hamburgerTop, { duration: 0.1, rotationZ: '0deg', transformOrigin: '50% 50%', ease: Sine.easeIn });
        gsap.to(hamburgerBottom, {
            duration: 0.15,
            rotationZ: '0deg',
            transformOrigin: '50% 50%',
            ease: Sine.easeIn,
            onComplete: () => {
                gsap.set(hamburgerMiddle, { opacity: 1 });
                gsap.to(hamburgerTop, { duration: 0.15, y: 0, ease: Quart.easeOut, clearProps: 'all' });
                gsap.to(hamburgerBottom, { duration: 0.15, y: 0, ease: Quart.easeOut, clearProps: 'all' });
            }
        });
    };

    const onBodyKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            toggleMenu();
        }
    };

    const openMenu = () => {
        activeElement = document.activeElement;
        html.addClass('js-menu-open');
        body.on('keyup', onBodyKeyUp);
        hamburger.attr('aria-expanded', 'true');
        gsap.killTweensOf([menu]);
        gsap.to(menu, {
            duration: 0.35,
            autoAlpha: 1,
            ease: Linear.easeInOut
        });
        hamburgerToX();
        Viewport.lockTabbing([menu, hamburger.get(0)]);
    };

    const closeMenu = () => {
        html.removeClass('js-menu-open');
        body.off('keyup', onBodyKeyUp);
        hamburger.attr('aria-expanded', 'false');
        gsap.killTweensOf([menu]);
        gsap.to(menu, {
            duration: 0.35,
            autoAlpha: 0,
            ease: Linear.easeInOut
        });
        xToHamburger();
        Viewport.releaseTabbing(activeElement);
    };

    const maybeCloseMenu = e => {
        const target = e.triggerTarget;
        const href = target.getAttribute('href');
        if (href.indexOf('#') !== -1) {
            toggleMenu();
        }
    };

    const toggleMenu = e => {
        if (e) {
            e.preventDefault();
        }
        if (menuOpen) {
            closeMenu();
        } else {
            openMenu();
        }
        menuOpen = !menuOpen;
    };

    const init = () => {
        hamburger.on('click', toggleMenu);
        menuLinks.on('click', maybeCloseMenu);
    };

    const destroy = () => {
        hamburger.off('click', toggleMenu);
        menuLinks.off('click', maybeCloseMenu);
        Viewport.releaseTabbing(activeElement);
    };

    return {
        init,
        destroy
    };
};
