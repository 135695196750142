export default (el, props) => {

    const init = () => {
        const chart = new Chart(el.getContext('2d'), {
            type: 'line',
            data: {
                labels: props.labels,
                datasets: [{
                    data: props.values,
                    fill: false,
                    borderColor: '#0A1F54',
                    pointBorderColor: '#F1534C',
                    pointBackgroundColor: '#ffffff',
                    pointBorderWidth: 4,
                    pointRadius: 7,
                    scaleLineColor: '#ffffff',
                    pointHoverRadius: 8
                }]
            },
            options: {
                legend: {
                    display: false
                },
                hover: {
                    animationDuration: 500
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            callback(label) {
                                return label > 1000000 ? `${label / 1000000} bn` : label;
                            }
                        }
                    }]
                },
                tooltips: {
                    xPadding: 20,
                    titleFontFamily: 'Gotham',
                    bodyFontFamily: 'Gotham',
                    bodySpacing: 10,
                    titleSpacing: 10,
                    titleFontSize: 10,
                    bodyFontSize: 10,
                    callbacks: {
                        label(tooltipItems) {
                            if (tooltipItems.yLabel < 1000000) {
                                return tooltipItems.yLabel;
                            }
                            return `${tooltipItems.yLabel.toString().replace(/(.)(?=(\d{3})+$)/g, '$1.')} SEK`;
                        }
                    }
                }

            }
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
