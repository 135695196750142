import { Loader } from '@googlemaps/js-api-loader';

export default (el, props) => {

    let observer;
    let mapLoaded = false;

    const loader = new Loader({
        apiKey: props.apiKey,
        version: 'weekly',
        libraries: ['places']
    });

    const loadMap = () => {
        mapLoaded = true;
        loader.load().then(async () => {
            const latlng = new google.maps.LatLng({lat: parseFloat(props.latitude), lng: parseFloat(props.longitude)});
            const { Map } = await google.maps.importLibrary('maps');

            const map = new Map(el, {
                center: latlng,
                zoom: 17
            });

            const marker = new google.maps.Marker({
                map,
                position: latlng,
                title: props.place
            });
        });
    };

    const onObserve = entries => {
        const { isIntersecting } = entries[0];
        if (isIntersecting && !mapLoaded) {
            observer.unobserve(el);
            loadMap();
        }
    };

    const init = () => {
        observer = new IntersectionObserver(onObserve);
        observer.observe(el);
    };

    const destroy = () => {
        observer.unobserve(el);
        observer.disconnect();
        observer = null;
    };

    return {
        init,
        destroy
    };
};
