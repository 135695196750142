import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {

    const button = $(el);
    const target = $(button.attr('href')).get(0);
    let expanded = false;

    const open = () => {
        if (!expanded) {
            expanded = true;
            button.attr('aria-expanded', 'true').text('Read less');
            gsap.to(target, {
                height: 'auto',
                duration: 0.35,
                ease: 'power2.inOut'
            });
        }
    };

    const close = () => {
        if (expanded) {
            expanded = false;
            button.attr('aria-expanded', 'false').text('Read more');
            gsap.to(target, {
                height: 0,
                duration: 0.35,
                ease: 'power2.inOut'
            });
        }
    };

    const toggle = e => {
        e.preventDefault();
        if (expanded) {
            close();
        } else {
            open();
        }
    };

    const init = () => {
        button.on('click', toggle);
    };

    const destroy = () => {
        button.off('click', toggle);
    };

    return {
        init,
        destroy
    };
};
