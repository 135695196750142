import { gsap, Cubic } from 'gsap';
import $ from '../core/Dom';

const init = () => {
    const body = $('body');

    $('a').on('click', e => {
        const href = e.triggerTarget.getAttribute('href');
        if (href && href.indexOf('#') !== -1 && href !== '#menu') {
            const bodyPadding = parseInt(body.css('paddingTop'), 10);
            const target = $(href);
            if (target.length) {
                e.preventDefault();
                gsap.to(window, { duration: 1, scrollTo: { y: target.position().top - bodyPadding, autoKill: false }, ease: Cubic.easeInOut, onComplete: () => { target.get(0).focus(); } });
            }
        }
    });
};

export default {
    init
};
